var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"header bg-gray pt-10 pb-3"},[_c('div',{staticClass:"container text-center"},[_c('h1',{staticClass:"display-4"},[_vm._v("Daas")]),_c('p',{staticClass:"lead-3 mt-6"},[_vm._v("Development as a service : Simpler / Low risk / Better")]),_vm._m(0),_c('p',[_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":"/contact"}},[_vm._v("Contact us to get started")])],1)])]),_c('section',{staticClass:"section pt-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row gap-y align-items-center py-7"},[_vm._m(1),_c('div',{staticClass:"col-md-5 ml-auto text-center text-md-left"},[_c('p',{staticClass:"lead-9 fw-900 lh-1 opacity-10"},[_vm._v("01")]),_c('h3',[_vm._v("Project brief")]),_c('p',[_vm._v("Use the "),_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("contact page")]),_vm._v(" to send us your a brief of your requirements. Tell us about your project, what is it that you want to do, what are your motivations, how far you are advanced and for when you would like this to go live ideally. Include any information you feel could be relevant at this stage and don't forget to include your contact details so we can come back to you."),_c('br')],1)])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"pb-3"},[_vm._v(" Codefit introduces its DaaS concept."),_c('br'),_vm._v(" Our goal ? Lower the barrier between your business, your projects and us your development team so get your products in front of your customers quicker."),_c('br'),_vm._v(" See below to understand how it works. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 text-center"},[_c('img',{attrs:{"src":require("../assets/daas-1.png"),"alt":"..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row gap-y align-items-center py-7"},[_c('div',{staticClass:"col-md-5 mr-auto text-center text-md-left"},[_c('p',{staticClass:"lead-9 fw-900 lh-1 opacity-10"},[_vm._v("02")]),_c('h3',[_vm._v("Meet and greet")]),_c('p',[_vm._v(" Once received, we look at the brief and information you have provided to us. We will then be in touch by email/phone to give some first feedback and organize a meeting to discuss matters further if we feel the project will be a good fit between you and us."),_c('br'),_vm._v(" The meeting will allow you to describe your idea in much more detail so we can get a the full picture of your goals. We will also ask questions along the way to make sure we understand what is it you are trying to achieve but also to identify any constraints that will apply for the project. ")])]),_c('div',{staticClass:"col-md-6 text-center order-first order-md-last"},[_c('img',{attrs:{"src":require("../assets/daas-2.png"),"alt":"..."}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row gap-y align-items-center py-7"},[_c('div',{staticClass:"col-md-6 text-center"},[_c('img',{attrs:{"src":require("../assets/daas-5.png"),"alt":"..."}})]),_c('div',{staticClass:"col-md-5 ml-auto text-center text-md-left"},[_c('p',{staticClass:"lead-9 fw-900 lh-1 opacity-10"},[_vm._v("03")]),_c('h3',[_vm._v("Offer provided")]),_c('p',[_vm._v(" If all is in order, we will work on providing you an offer including the following elements : "),_c('ul',[_c('li',[_vm._v("High level analysis of your project")]),_c('li',[_vm._v("High level duration")]),_c('li',[_vm._v("Risk level identified")]),_c('li',[_vm._v("Iteration proposal")]),_c('li',[_vm._v("Terms and condition")])]),_vm._v(" The offer contains enough information to get started on the project. Whilst it is possible to have a bit of back and forth between parties to obtain the offer, it is important to respect the concept behind Daas, meaning that only the minimum amount of information to get started is required at this stage. This phase should be quick. The terms and conditions contain one crucial element common to all our Daas projects : you can stop whenever you want (at the end of an iteration). "),_c('br'),_vm._v(" This is the critical factor that allows to lower the entry barrier for the project. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row gap-y align-items-center py-7"},[_c('div',{staticClass:"col-md-5 text-center text-md-left"},[_c('p',{staticClass:"lead-9 fw-900 lh-1 opacity-10"},[_vm._v("04")]),_c('h3',[_vm._v("Kick off")]),_c('p',[_vm._v(" You give the green light and your project starts moving fast from there on : "),_c('ul',[_c('li',[_vm._v("Project management tools / Wiki are created for you project")]),_c('li',[_vm._v("Kickoff meeting is organized to prioritize first user stories")]),_c('li',[_vm._v("Technical environments are created")]),_c('li',[_vm._v("Development starts and is on the way until Demo 1")])])])]),_c('div',{staticClass:"col-md-6 ml-auto text-center order-first order-md-last"},[_c('img',{attrs:{"src":require("../assets/daas-3.png"),"alt":"..."}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row gap-y align-items-center py-7"},[_c('div',{staticClass:"col-md-5 text-center text-md-left"},[_c('p',{staticClass:"lead-9 fw-900 lh-1 opacity-10"},[_vm._v("05")]),_c('h3',[_vm._v("Demo day")]),_c('p',[_vm._v(" After 2 or 3 weeks it is demo day : "),_c('br'),_vm._v(" - You were involved in the sprint and have a feel of how things will be going along in the future "),_c('br'),_vm._v(" - We demo the items developed "),_c('br'),_vm._v(" - Like what you see ? We can start planning sprint 2. "),_c('br'),_vm._v(" - Don’t like what you see ? No problem, you can stop any time. ")])]),_c('div',{staticClass:"col-md-6 ml-auto text-center order-first order-md-last"},[_c('img',{attrs:{"src":require("../assets/daas-4.png"),"alt":"..."}})])])
}]

export { render, staticRenderFns }