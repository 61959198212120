<template>
  <div>
    <header class="header bg-gray pt-10 pb-01">
      <div class="container text-center">
        <h1 class="display-4">Contact Us</h1>
      </div>
    </header>
    <main class="main-content">
      <section class="section">
        <div class="container">
          <form class="row gap-y" @submit="onFormSubmitted" data-form="mailer" method="POST">
            <div class="col-lg-9">
              <h3>Lets talk</h3>
              <p class="lead-2">
                Let us know how we can help. If you are enquirying about one of
                your projects. Give us as much information as possible so we can
                get back to you
              </p>
              <br />

              <div class="alert alert-success d-on-success">
                We received your message and will contact you back soon.
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <input v-model="form.name" class="form-control form-control-lg" name="name" placeholder="Name"
                    type="text" required />
                </div>

                <div class="form-group col-md-6">
                  <input v-model="form.email" class="form-control form-control-lg" name="email" placeholder="Email"
                    type="email" required />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <select v-model="form.topic" class="form-control form-control-lg" name="topic" placeholder="Topic"
                    required>
                    <option value="generalEnquiry">General enquiry</option>
                    <option value="greenProgramming">Green programming</option>
                    <option value="daas">Daas project</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <textarea v-model="form.message" class="form-control form-control-lg" rows="4" placeholder="Message"
                  name="message" required></textarea>
              </div>

              <vue-recaptcha ref="recaptcha" sitekey="6LcKXnYUAAAAAP8xYU7rz0xWQTDgViUhNn6rO4k9"
                @verify="onRecaptchaCompleted" @expired="onRecaptchaExpired">
              </vue-recaptcha>
              <button class="mt-5 btn btn-lg btn-primary" type="submit">
                Send message
              </button>

              <div class="mt-5" v-if="messageProgressPct > 0">
                <p class="text-center">Sending message...</p>
                <div class="progress">
                  <div class="progress-bar bg-secondary" role="progressbar" :style="'width:' + messageProgressPct + '%'"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 ml-auto text-center text-lg-left">
              <hr class="d-lg-none" />
              <h3>Find Us</h3>
              <br />
              <p><i class="fa fa-map-marker"></i> Sydney Northern Beaches</p>
              <p><i class="fa fa-envelope-o"></i> info@codefit.io</p>
              <!-- <div class="fw-400">Follow Us</div>
              <div class="social social-sm social-inline">
                <a class="social-twitter" href="#"><i class="fa fa-twitter"></i></a>
                <a class="social-facebook" href="#"><i class="fa fa-facebook"></i></a>
                <a class="social-instagram" href="#"><i class="fa fa-instagram"></i></a>
                <a class="social-dribbble" href="#"><i class="fa fa-dribbble"></i></a>
              </div> -->
            </div>
          </form>
        </div>
      </section>
    </main>
  </div>
</template>


<script lang="ts">
import axios from "axios";
import toastr from "toastr";
import Vue from "vue";
import Component from "vue-class-component";
import VueRecaptcha from "vue-recaptcha";

@Component({
  components: { VueRecaptcha },
})
export default class ContactUs extends Vue {
  private form = {
    name: "",
    email: "",
    message: "",
    topic: "generalEnquiry",
    gRecaptchaResponse: "",
  };

  private messageProgressPct = 0;
  private messageProgressInterval: any;

  onRecaptchaCompleted(response: string) {
    this.form.gRecaptchaResponse = response;
  }
  onRecaptchaExpired() {
    this.form.gRecaptchaResponse = "";
  }

  async onFormSubmitted(e: Event) {
    e.preventDefault();

    if (!this.form.gRecaptchaResponse) {
      toastr.warning(
        "You must complete the captcha before submitting the form"
      );
      return;
    }

    const url =
      "https://codefit-website-function-app.azurewebsites.net/api/contact-us?code=CfDJ8AAAAAAAAAAAAAAAAAAAAADjjMuudL_dOn-6nN5Vhmnn0BucDgxXZoOyNXC7ry6bcPtYwQ25iutwC_xi_QuMdHMhRmZiJdGsZ8ncduS1aRIhITu8UNkiv5QpzGmC3X-mDl-rgpFFqW-iKuVgHaRXuRkX5ZsQVJ_S_S_HTzpM36kV2WGU4cMRDYB_Sxqg5ijNRQ";
    try {
      this.messageProgressPct = 0;
      this.startMessageProgress();
      let response = await axios.post(url, this.form);
      this.messageProgressPct = 100;
      this.resetMessageProgress();
      toastr.success(
        "Someone will be in touch soon.",
        "Message sent successfully."
      );
    } catch (error) {
      this.resetMessageProgress();
      toastr.error(
        "There was an issue while sending your message. Use direct email as workaround if the issue persists."
      );
    }

    this.resetForm();
    (this.$refs['recaptcha'] as any)?.reset()
  }

  startMessageProgress(expectedTimeInMilliseconds = 5000) {
    const progressIntervalInMilliseconds = 1000; //update the progress indicator every 1s
    const stepSize =
      (progressIntervalInMilliseconds / expectedTimeInMilliseconds) * 100;

    this.messageProgressInterval = setInterval(() => {
      this.messageProgressPct += stepSize;
      if (this.messageProgressPct >= 100) {
        clearInterval(this.messageProgressInterval);
      }
    }, progressIntervalInMilliseconds);
  }

  resetMessageProgress() {
    this.messageProgressPct = 0;
    clearInterval(this.messageProgressInterval);
  }

  resetForm() {
    this.form.name = "";
    this.form.email = "";
    this.form.message = "";
    this.form.topic = "generalEnquiry";
    this.form.gRecaptchaResponse = "";
  }
}
</script>

