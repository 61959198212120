import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
(<any>window).$ = (<any>window).jQuery = require('jquery');
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import 'et-line/style.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
