<template>
    <div>
      <header class="header text-black" data-overlay="7" :style="{'background-image': 'url(' + require('../assets/background-computer.jpg') + ')'}">
        <div class="container">
          <div class="row align-items-center h-100">
            <div class="col-md-8 mx-auto text-center">
              <p><img src="../assets/codefit-logo-white.png" width="30%" alt="..."></p>
              <!-- <h1 class="display-41" data-font="Raleway:800">Codefit</h1> -->
              <h1 style="color : #fff" class="display-41">Top quality software that fits your needs</h1>
              <router-link class="btn btn-lg btn-secondary" to="/daas">Get started</router-link>
              <router-link class="ml-5 btn btn-lg btn-info" to="/what-we-do">Our services</router-link>
            </div>
          </div>
        </div>
      </header>
      <section class="section py-6">
        <div class="container">
            <h3 class="text-center">Our clients</h3>
            <p class="lead text-center">Join our clients that have worked with Codefit previously</p>

          <div class="row partner">
            <div class="col-6 col-md-4">
              <img src="../assets/partners/allianz-logo.png" alt="Allianz">
            </div>

            <div class="col-6 col-md-4">
              <img src="../assets/partners/racq-logo.png" alt="RACQ">
            </div>

            <div class="col-6 col-md-4">
              <img src="../assets/partners/atc-logo.png" alt="AussieTravelCover">
            </div>
          </div>

        </div>
      </section>
      <div class="main-content">
        <section class="section bg-gray">
            <div class="container">
              <div class="row gap-y">
                <div class="col-md-6 col-xl-4">
                  <div class="media">
                    <div class="lead-6 line-height-1 text-dark w-70px"><i class="icon-genius"></i></div>
                    <div class="media-body">
                      <h5>Expertise</h5>
                      <p>Codefit was founded and is run by IT experts. We take care of your web applications so you can run and stay focused on your core business.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4">
                  <div class="media">
                    <div class="lead-6 line-height-1 text-primary w-70px"><i class="icon-tools"></i></div>
                    <div class="media-body">
                      <h5>Quality</h5>
                      <p>We only develop top quality software. Our philosophy is that if we don't think its good enough, why would you ?</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4">
                  <div class="media">
                    <div class="lead-6 line-height-1 text-info w-70px"><i class="icon-focus"></i></div>
                    <div class="media-body">
                      <h5>Focus</h5>
                      <p>We take the time to fully focus on your projects and understand what you want to achieve.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4">
                  <div class="media">
                    <div class="lead-6 line-height-1 text-warning w-70px"><i class="icon-happy"></i></div>
                    <div class="media-body">
                      <h5>Simplicity</h5>
                      <p>"Simplicity is the ultimate sophistication". Leonardo Da Vinci. We couldn't agree more with this philosophy and strive to apply it to all our projects.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4">
                  <div class="media">
                    <div class="lead-6 line-height-1 text-danger w-70px"><i class="icon-layers"></i></div>
                    <div class="media-body">
                      <h5>Flexibility</h5>
                      <p>Codefit will fit your needs whatever they are. We have redefined what flexibility means with our 'Extreme Flexibility' concept.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-4">
                  <div class="media">
                    <div class="lead-6 line-height-1 text-success w-70px"><i class="fa fa-shield"></i></div>
                    <div class="media-body">
                      <h5>Security</h5>
                      <p>Security is at the forefront of all the software we deliver.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="section">
            <div class="container">
              <div class="row gap-y align-items-center">

                <div class="col-md-6 text-center text-md-left order-md-2">
                  <h3 class="fw-500">DAAS</h3>
                  <p class="small-2 text-uppercase fw-500 ls-1">Development as a service</p>
                  <br>
                  <p>
                    You might have heard of IaaS, PaaS or even SaaS ? <br/>
  At Codefit we've introduced the concept of DaaS which stands for Development as a Service.<br/>
  The idea behind this is straightforward: we want to lower the barrier between your business, your projects and us, your software engineering team.
  The objective is to get projects up and running faster, without stress so you get your new ideas in front of your customers quicker. It's a win-win situation.
                  </p>
                  <router-link class="btn btn-secondary" to="daas">Read more</router-link>
                </div>

                <div class="col-md-5 mx-auto">
                  <img class="rounded-md" src="../assets/daas/laptop-2838917__340.jpg" alt="...">
                </div>

              </div>
            </div>
        </section>
        <section class="section bg-gray">
          <div class="container">
            <div class="row gap-y align-items-center">
              <div class="col-md-6 text-md-right">
                <h3 class="fw-500">Green Programming</h3>
                <p class="text-uppercase fw-500 ls-1">Our contribution to helping the planet</p>
                <br>
                <p>
                  We all know that our planet is suffering from the massive footprint that humans have put on it. At Codefit we are particularly sensitve about this topic and wanted to play our part in making difference. This is why we decided to launch the Green Programming project. 
  We give 5 hours a week to help projects and people with sustainable ideas. 
                </p>
                <router-link class="btn btn-secondary" to="green-programming">Read more</router-link>
              </div>

              <div class="col-md-5 mx-auto">
                <!-- <img class="rounded-md" src="../assets/green/notebook-405755__340.jpg" alt="..."> -->
                <img class="rounded-md" src="../assets/green/laptop-2055522__340.jpg" alt="...">
              </div>

            </div>
          </div>
        </section>
        <section class="section">
          <div class="container">
              <div class="row justify-content-md-center">
                <div class="col-auto"></div>
                <div class="col-6">
                  <blockquote class="blockquote" style="">
                    <svg data-prefix="fas" data-icon="quote-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="36" height="36" 
                    class="text-secondary svg-inline--fa fa-quote-left fa-w-16 testimonial-block__quote-left-icon"><path fill="currentColor" d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path></svg>
                    <p class="lead-4">
                      We have been using Codefit since 2016 to develop our issuing portals and backend applications. 
                      Codefit’s technical and industry knowledge with a straightforward project methodology has 
                      delivered projects that have exceeded our expectations.
                    </p>
                    <br>
                    <!-- <div><img class="avatar avatar-xl" src="https://codefit.atlassian.net/secure/useravatar?ownerId=peter&avatarId=10700&noRedirect=true" alt="..."></div> -->
                    <footer><b>Peter White</b> Aussie Travel Cover</footer>
                  </blockquote>
                </div>
                <div class="col-auto"></div>
              </div>
          </div>
        </section>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Home extends Vue {

}
</script>
