<template>
  <!-- Navbar -->
  <nav
    class="navbar navbar-expand-lg"
    v-bind:class="{ 'navbar-light': ligthNavbarPages.indexOf($route.name)  !== -1,  'navbar-dark': ligthNavbarPages.indexOf($route.name)  === -1}"
  >
    <div class="container">
      <div class="navbar-left mr-4">
        <button class="navbar-toggler" type="button">☰</button>
        <router-link class="navbar-brand" style="color:#15a085" to="/">
          <img class="logo-dark" src="../assets/codefit-logo.png" width="100px" alt="logo" />
          <img class="logo-light" src="../assets/codefit-logo-white.png" width="100px" alt="logo" />
          <!-- CODEFIT -->
        </router-link>
      </div>

      <section class="navbar-mobile">
        <span class="navbar-divider d-mobile-none"></span>
        <ul class="nav nav-navbar nav-uppercase mr-auto">
          <li class="nav-item nav-link exact">
            <router-link class="nav-link" to="/" exact>Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">About us</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/what-we-do">What we do</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/daas">Daas</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/green-programming">Green programming</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">Contact us</router-link>
          </li>
        </ul>
      </section>
    </div>
  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class HeaderComponent extends Vue {
  // @Prop()
  // private show!: string;

  ligthNavbarPages = ["home", "greenProgramming"];
  
  @Watch("$route")
  routeChange(to: any, from: any) {
    this.navbarClose()
  }

  created() {
    this.registerNavbarListeners();
  }

  private registerNavbarListeners() {
    /**
     * Toggle navbar
     */
    $(document).on("click", ".navbar-toggler", () => {
      this.navbarToggle();
    });

    /**
     * Tapping on the backdrop will close the navbar
     */
    $(document).on("click", ".backdrop-navbar", () => {
      this.navbarClose();
    });

    /**
     * Toggle menu open on small screen devices
     */
    $(document).on(
      "click",
      ".navbar-open .nav-navbar > .nav-item > .nav-link",
      function() {
        $(this)
          .closest(".nav-item")
          .siblings(".nav-item")
          .find("> .nav:visible")
          .slideUp(333, "linear");
        $(this)
          .next(".nav")
          .slideToggle(333, "linear");
      }
    );
  }

  private navbarToggle() {
    let body = $("body");
    let navbar = $(".navbar");

    body.toggleClass("navbar-open");
    if (body.hasClass("navbar-open")) {
      navbar.prepend('<div class="backdrop backdrop-navbar"></div>');
    }
  }

  private navbarClose() {
    $("body").removeClass("navbar-open");
    $(".backdrop-navbar").remove();
  }
}
</script>

