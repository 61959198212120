<template>
    <footer class="footer">
      <div class="container">
        <div class="row gap-y align-items-center">

          <div class="col-6 col-lg-9">
              {{currentYear}} Codefit <br/>
              ABN : 56 602 533 086
          </div>

          <div class="col-6 col-lg-3 text-right">
              <img src="../assets/codefit-logo.png" width="100px"/>
          </div>
          <!-- <div class="col-6 col-lg-3 text-right order-lg-last">
            <div class="social">
              <a class="social-facebook" href="https://www.facebook.com/thethemeio"><i class="fa fa-facebook"></i></a>
              <a class="social-twitter" href="https://twitter.com/thethemeio"><i class="fa fa-twitter"></i></a>
              <a class="social-instagram" href="https://www.instagram.com/thethemeio/"><i class="fa fa-instagram"></i></a>
            </div>
          </div> -->

          <!-- <div class="col-lg-6">
            <div class="nav nav-bold nav-uppercase nav-trim justify-content-lg-center">
              <a class="nav-link" href="../uikit/index.html">Elements</a>
              <a class="nav-link" href="../block/index.html">Blocks</a>
              <a class="nav-link" href="../page/about-1.html">About</a>
              <a class="nav-link" href="../blog/grid.html">Blog</a>
              <a class="nav-link" href="../page/contact-1.html">Contact</a>
            </div>
          </div> -->

        </div>
      </div>
    </footer>
</template>

<script lang="ts">
import Vue from "vue";
export default class FooterComponent extends Vue {

  currentYear = new Date().getFullYear()

}
</script>
