<template>
  <div id="app">
    
    <header-component></header-component>
    <router-view/>
    <footer-component></footer-component>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default Vue.extend({
  components: {
    HeaderComponent,
    FooterComponent
  }
});
</script>



<style>
</style>
