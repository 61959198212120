import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import About from './views/About.vue';
import WhatWeDo from './views/WhatWeDo.vue';
import Daas from './views/Daas.vue';
import GreenProgramming from './views/GreenProgramming.vue';
import ContactUs from './views/ContactUs.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/what-we-do',
      name: 'whatWeDo',
      component: WhatWeDo,
    },
    {
      path: '/daas',
      name: 'daas',
      component: Daas,
    },
    {
      path: '/green-programming',
      name: 'greenProgramming',
      component: GreenProgramming,
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactUs,
    },
  ],
});
